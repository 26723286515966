import React from 'react';

import { IModal } from '../interfaces';
import styles from '../scss/components/modal.module.scss';
import Button from './button';

const Modal = (props: IModal) => {
    return (
        <div className={ styles.container }>
            <div className={ styles.modal }>
                <div className={ styles['close-container'] } onClick={ () => props.closeAction() }>
                    <div className={ styles.close }></div>
                </div>
                <div className={ styles.content }>{ props.children }</div>
                <Button action={ () => props.action } disabled={ props.disabled }>
                    { props.buttonText }
                </Button>
            </div>

        </div>
    );
}

export default Modal;