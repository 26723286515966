import React, { useState } from 'react';

import { IItem, IList } from '../interfaces';
import styles from '../scss/components/select.module.scss';

import SelectItem from './select-item';

const Select = (props: IList) => {
    const [toggleList, setToggleList] = useState<boolean>(false);
    const [label, setLabel] = useState<string>(props.label);

    const handleClick = (item?: IItem) => {
        if (!!item) {
            setLabel(item.name);
            props.select(item.id);
        } else {
            setLabel(props.label);
            props.select(null);
        }
        setToggleList(value => !value);
    }

    return (
        <div className={ styles.container }>
            <div className={`${ styles.select } ${ !!toggleList ? styles.open : '' }`} onClick={ () => handleClick() }>
                <span className={`${ styles.text } ${ label !== props.label ? styles.selected : ''}`}>{ label }</span>
                <div className={ styles['arrow-container'] }>
                    <div className={ styles.arrow }></div>
                </div>
            </div>
            <ul className={ styles.list }>
                { props.items.map((item) => <SelectItem item={ item } onClick={ handleClick } key={ item.id } />)}
            </ul>
        </div>
    );
}

export default Select;