import React from 'react';

import styles from '../scss/components/loading-i.module.scss';

const Loading = (props: { text: string }) => {
    return (
        <div className={ styles['master-container'] }>
            <div className={ styles.container }>
                <div className={ styles.crescent }>
                    <span></span><span></span>
                </div>
                <div className={`${ styles.star } ${ styles.s1 }`}>
                    <span></span><span></span>
                </div>
                <div className={`${ styles.star } ${ styles.s2 }`}>
                    <span></span><span></span>
                </div>
                <div className={`${ styles.star } ${ styles.s3 }`}>
                    <span></span><span></span>
                </div>
                <div className={`${ styles.star } ${ styles.s4 }`}>
                    <span></span><span></span>
                </div>
                <div className={`${ styles.star } ${ styles.s5 }`}>
                    <span></span><span></span>
                </div>
                <div className={`${ styles['small-star'] } ${ styles.ss1 }`}>
                    <span></span><span></span>
                </div>
                <div className={`${ styles['small-star'] } ${ styles.ss2 }`}>
                    <span></span><span></span>
                </div>
                <div className={`${ styles['small-star'] } ${ styles.ss3 }`}>
                    <span></span><span></span>
                </div>
                <div className={`${ styles['small-star'] } ${ styles.ss4 }`}>
                    <span></span><span></span>
                </div>
                <div className={`${ styles['small-star'] } ${ styles.ss5 }`}>
                    <span></span><span></span>
                </div>
                <div className={`${ styles['small-star'] } ${ styles.ss6 }`}>
                    <span></span><span></span>
                </div>
                <div className={`${ styles['small-star'] } ${ styles.ss7 }`}>
                    <span></span><span></span>
                </div>
                <div className={`${ styles['small-star'] } ${ styles.ss8 }`}>
                    <span></span><span></span>
                </div>
                <div className={ styles.hut }>
                    <hr />
                    <hr />
                    <hr />
                    <hr />
                    <hr />
                    <hr />
                    <div className={ styles.doorway }>
                        <hr className={ styles.split } />
                    </div>
                </div>
                <div className={ styles['fire-pit'] }>
                    <div className={ styles.fire }>
                        <div className={ styles['inner-fire'] }>
                        </div>
                    </div>
                    <hr className={ styles.log1 } />
                    <hr className={ styles.log2 } />
                </div>
                <div className={`${ styles.tree } ${ styles.one }`}>
                    <hr className={ styles.trunc } />
                    <hr className={ styles.leaves } />
                    <hr className={ styles.leaves } />
                    <hr className={ styles.leaves } />
                    <hr className={ styles.leaves } />
                </div>
                <div className={`${ styles.tree } ${ styles.two }`}>
                    <hr className={ styles.trunc } />
                    <hr className={ styles.leaves } />
                    <hr className={ styles.leaves } />
                    <hr className={ styles.leaves } />
                    <hr className={ styles.leaves } />
                </div>
                <div className={`${ styles.tree } ${ styles.three }`}>
                    <hr className={ styles.trunc } />
                    <hr className={ styles.leaves } />
                    <hr className={ styles.leaves } />
                    <hr className={ styles.leaves } />
                    <hr className={ styles.leaves } />
                </div>
                <div className={`${ styles.tree } ${ styles.four }`}>
                    <hr className={ styles.trunc } />
                    <hr className={ styles.leaves } />
                    <hr className={ styles.leaves } />
                    <hr className={ styles.leaves } />
                    <hr className={ styles.leaves } />
                </div>
                <div className={ styles.hill }></div>
            </div>
            <span className={ styles['loading-text'] }>{ props.text }</span>
        </div>
    )
}

export default Loading;