import React, { useState } from 'react';

import { IItemSwitch } from '../interfaces';
import styles from '../scss/pages/testing.module.scss';

import Loading from '../components/loading-i';
import Loading2 from '../components/loading-c';
import Switch from '../components/switch';

const Testing = () => {
    const [ active, setActive ] = useState<boolean>();
    const changeActive = (item: boolean) => {
        console.log(active);
        setActive(item);
    }
    const items: IItemSwitch[] = [
        {
            label: 'Novo',
            onChange: () => changeActive(true),
            checked: active
        },
        {
            label: 'Retorno',
            onChange: () => changeActive(false),
            checked: !active
        }
    ];

    return (
        <div className={ styles.container }>
            <Switch name='test' items={ items } />
            { active === true ?
                <Loading text='Loading... Please Wait!' />
            :
                <Loading2 />
            }
        </div>
    )
}

export default Testing;