export default {
    CONSULTING_AREAS: [
        { id: 1, name: 'Amor' },
        { id: 2, name: 'Caminhos' },
        { id: 3, name: 'Dinheiro' },
        { id: 4, name: 'Saúde' },
        { id: 5, name: 'Outros / Nada específico' }
    ],
    CONSULTING_ENTITIES: [
        { id: 1, name: 'Maria Bonita - Mãe Tati' },
        { id: 2, name: 'Tranca Ruas - Pai Allan' },
        { id: 3, name: 'Maria Padilha - Mel' },
        { id: 4, name: 'Sete Saias - Mary' },
        { id: 5, name: 'Cigana Sete Estradas - Amanda' },
        { id: 6, name: 'Cigana Esmeralda - Marli' },
        { id: 7, name: 'Exu 7 da Lira - Wesley' },
        { id: 8, name: 'Marabô - Marcio' },
        { id: 9, name: 'Rosa Caveira - Barbara' }
    ],
    KEY_CHARACTER_AMOUNT: 4
}