import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Scheduling from './pages/scheduling';
import Testing from './pages/testing';

function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='' element={<Scheduling />} />
                <Route path='testing' element={<Testing />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Router;