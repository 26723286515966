import React from 'react';

import styles from '../scss/components/loading-c.module.scss';

const Loading = () => {
    return (
        <div className={ styles.holder }>
            <div className={ styles.candle }>
                <div className={ styles['blinking-glow'] }></div>
                <div className={ styles.thread }></div>
                <div className={ styles.glow }></div>
                <div className={ styles.flame }></div>
            </div>
        </div>
    )
}

export default Loading;