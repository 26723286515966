import React from 'react';

import styles from '../scss/components/input-field.module.scss';
import { IInputField } from '../interfaces';

const InputField = (props: IInputField) => {
    return (
        <div className={ styles.container }>
            <input
                className={ `${styles.input} ${ props.value && styles['filled-input']}` }
                type={ props.type }
                name={ props.name }
                value={ props.value }
                pattern={ props.validator.pattern }
                minLength={ props.validator.minLength }
                maxLength={ props.validator.maxLength }
                onChange={(e) => { props.setValue(e.currentTarget.value); }}
                required
            />
            <label className={ styles.label }>{ props.placeholder }</label>
        </div>
    )
}

export default InputField;