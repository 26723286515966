import React from 'react';

import styles from '../scss/components/input-checkbox.module.scss';
import { ICheckbox } from '../interfaces';

const InputCheckbox = (props: ICheckbox) => {
    return (
        <div className={ styles.container }>
            <label className={`${ styles.wrapper } ${ props.check && styles['checked-wrapper'] }` }>
                <input type="checkbox" checked={ props.check } onChange={ () => props.onChange() } />
                    <div className={ styles.checkmark }></div>
                    <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg" className={ styles.celebrate }>
                        <polygon points="0,0 10,10"></polygon>
                        <polygon points="0,25 10,25"></polygon>
                        <polygon points="0,50 10,40"></polygon>
                        <polygon points="50,0 40,10"></polygon>
                        <polygon points="50,25 40,25"></polygon>
                        <polygon points="50,50 40,40"></polygon>
                    </svg>
            </label>
            <span className={ styles.text }>{ props.children }</span>
        </div>
    );
}

export default InputCheckbox;