import React from 'react';

import { IItem } from '../interfaces';
import styles from '../scss/components/select-item.module.scss';

const SelectItem = (props: { item: IItem, onClick: Function }) => {
    const item = props.item;

    return (
        <li
            key={ item.id }
            className={ styles.item }
            value={ item.id }
            onClick={ () => props.onClick(item) }
        >
            <span className={ styles.text }>{ item.name }</span>
        </li>
    );
}

export default SelectItem;