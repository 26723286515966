import React, { useEffect, useState } from 'react';

import { IList } from '../interfaces';
import styles from '../scss/components/select-multi.module.scss';

import SelectMultiItem from './select-multi-item';

const SelectMulti = (props: IList) => {
    const [ toggleList, setToggleList ] = useState<boolean>(false);
    const [ label, setLabel ] = useState<string>(props.label);

    const setValuesAndLabel = (checkedItems: NodeListOf<Element>) => {
        const areas: number[] = [];
        checkedItems.forEach((item: Element) => {
            const value = item.getAttribute('value');
            if (!!value) areas.push(+value);
        });
        setLabel(`${checkedItems.length} Selecionado(s)`);
        props.select(areas);
    }

    const setEmptyValuesAndLabel = () => {
        setLabel(props.label);
        props.select([]);
    }

    useEffect(() => {
        // Gambi pra achar os elementos selecionados
        const listComponent = document.querySelector(`.${styles.list}`);
        const checked = listComponent?.querySelectorAll('[class*="select-multi-item_checked"]');
        (checked && checked.length > 0) ? setValuesAndLabel(checked) : setEmptyValuesAndLabel();
    }, [ toggleList ]);

    return (
        <div className={ styles.container }>
            <div className={`${ styles.select } ${ !!toggleList ? styles.open : '' }`} onClick={ () => setToggleList(value => !value) }>
                <span className={ styles.text }>{ label }</span>
                <div className={ styles['arrow-container'] }>
                    <div className={ styles.arrow }></div>
                </div>
            </div>
            <ul className={ styles.list }>
                { props.items.map((item) => <SelectMultiItem item={ item } key={ item.id } />)}
            </ul>
        </div>
    );
}

export default SelectMulti;