import React from 'react';

import styles from '../scss/components/logo.module.scss';

const Logo = () => {
    const logo: string = `logo192.png`;
    return (
        <div className={ styles.container }>
            <img src={ logo } alt='logo' className={ styles.logo } />
        </div>
    )
}

export default Logo;