import React from 'react';

import styles from  '../scss/components/button.module.scss';
import { IButton } from '../interfaces';

const Button = (props: IButton) => {
    return (
        <div className={ `${styles.container} ${ props.class && props.class.join(' ') }` }>
            <button className={ styles.button } onClick={ props.action() } disabled={ props.disabled }>
                { props.children }
            </button>
        </div>
    );
}

export default Button;