import React from 'react';

import { IItemSwitch, ISwitch } from '../interfaces';
import styles from '../scss/components/switch.module.scss';

const Switch = (props: ISwitch) => {
    const items: IItemSwitch[] = props.items;
    const name: string = props.name;
    return (
        <div className={ styles.container }>
            <div className={ styles.tabs }>
                { items.map((item, index) => {
                    return (
                        <>
                            <input
                                type='radio'
                                id={`radio-${ index + 1 }`}
                                name={ name }
                                onChange={ () => item.onChange() }
                                checked={ item.checked }
                            />
                            <label className={ styles.tab } htmlFor={`radio-${ index + 1 }`}>{ item.label }</label>
                        </>
                    );
                })}
                <span className={ styles.glider }></span>
            </div>
        </div>
    )
}

export default Switch;