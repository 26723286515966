import React, { useState } from 'react';

import { IData, IItemSwitch, IInputPattern } from '../interfaces';
import constants from '../constants';
import styles from '../scss/pages/scheduling.module.scss';

import Button from '../components/button';
import InputCheckbox from '../components/input-checkbox';
import InputField from '../components/input-field';
import Logo from '../components/logo';
import Modal from '../components/modal';
import Select from '../components/select';
import SelectMulti from '../components/select-multi';
import Switch from '../components/switch';

const nameValidator: IInputPattern = {
    pattern: '^[a-zA-záàâãéèêíïóôõöúçÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇ ]+$',
    minLength: 3,
    maxLength: 80
};
const phoneValidator: IInputPattern = {
    pattern: '^[\\+][1-9]{1,3}[ ]?(?:\\([0-9]{2}\\)|[0-9]{2})[ ]?[0-9]{4,5}[\\-]?[0-9]{4}$',
    minLength: 13,
    maxLength: 20
};
const passwordValidator: IInputPattern = {
    pattern: '^[\\+][1-9]{1,3}[ ]?(?:\\([0-9]{2}\\)|[0-9]{2})[ ]?[0-9]{4,5}[\\-]?[0-9]{4}$',
    minLength: 4,
    maxLength: 8
};

const Scheduling = () => {
    const [isReturnVisit, setIsReturnVisit] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [phone, setPhoneNumber] = useState<string>('');
    const [areas, setAreas] = useState<number[]>([]);
    const [entity, setEntity] = useState<number>();
    const [selectByEntity, setSelectByEntity] = useState<boolean>(false);
    const [query, setQuery] = useState<IData>();
    const [key, setKey] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

    const options: IItemSwitch[] = [
        {
            label: 'Novo',
            checked: !isReturnVisit,
            onChange: () => setIsReturnVisit(false)
        },
        {
            label: 'Retorno',
            checked: isReturnVisit,
            onChange: () => setIsReturnVisit(true)
        },
    ]

    const canConfirm = (): boolean => {
        if (!validateInputField(name, nameValidator)) return true;
        if (!validateInputField(phone, phoneValidator)) return true;
        if (isReturnVisit) return false;
        if (areas.length > 0 && !selectByEntity) return false;
        if (entity !== null && selectByEntity) return false;
        return true;
    }

    const validateInputField = (text: string, validator: IInputPattern): boolean => {
        if (!validatePattern(text, validator.pattern)) return false;
        if (text.length < validator.minLength || text.length > validator.maxLength) return false;
        return true;
    }

    const validatePattern = (text: string, pattern: string): boolean => {
        const regex = new RegExp(pattern);
        return (regex.test(text));
    }

    const confirm = (): void => {
        setQuery({
            name,
            phone,
            returnVisit: isReturnVisit,
            selectByEntity,
            areas,
            entity
        });
        setKey(generateKey());
    }

    const generateKey = (): string => {
        const keys: string[] = [];
        for (let i = 0; i < constants.KEY_CHARACTER_AMOUNT; i++) {
            const letter = convertNumberToChar();
            keys.push(letter)
        }
        const generatedKey: string = keys.join('');
        return generatedKey;
    }

    const convertNumberToChar = (): string => {
        const characterA = 'A'.charCodeAt(0);
        const letterNumber = Math.floor(Math.random() * 26);
        return String.fromCharCode(characterA + letterNumber);
    }

    const schedule = async () => {
        console.log(password !== '');
        // await sendToBackend(query)
        //     .then(data => {

                setIsReturnVisit(false);
                // setName('');
                // setPhoneNumber('');
                setAreas([]);
                setEntity(undefined);
                setSelectByEntity(false);
                setKey('');
                setPassword('');
                setOpenSuccessModal(true);
            // }).catch(error => {

            // });
    }

    return (
        <div className={styles.container}>
            <Logo />
            <InputField
                name='user-name'
                placeholder='Nome'
                type='text'
                value={ name }
                validator={ nameValidator }
                setValue={ setName }
            />
            <InputField
                name='user-phone'
                placeholder='Telefone'
                type='text'
                value={ phone }
                validator={ phoneValidator }
                setValue={ setPhoneNumber }
            />
            <Switch name='tipo-consulta' items={ options } />
            { isReturnVisit === false && (
                <>
                    <div className={ styles['checkbox-container'] }>
                        <InputCheckbox check={ selectByEntity } onChange={ () => setSelectByEntity(value => !value) }>Selecionar por entidade</InputCheckbox>
                    </div>
                    { selectByEntity ?
                        <Select label='Entidade' items={ constants.CONSULTING_ENTITIES } select={ setEntity } />
                        :
                        <SelectMulti label='Área da consulta' items={ constants.CONSULTING_AREAS } select={ setAreas } />
                    }
                </>
            )}
            <Button action={ () => confirm } disabled={ canConfirm() }>
                Agendar
            </Button>
            { key !== '' &&
                <Modal
                    buttonText='Confirmar'
                    disabled={ password === '' }
                    action={ schedule }
                    closeAction={ () => setKey('') }
                >
                    <div className={ styles['key-text'] }>
                            Chave:
                            <span>{ key }</span>
                    </div>
                    <span>Insira a senha gerada com a chave acima</span>
                    <InputField
                        name='password'
                        placeholder='Senha'
                        type='password'
                        value={ password }
                        validator={ passwordValidator }
                        setValue={ setPassword }
                    />
                </Modal>
            }
            { openSuccessModal &&
                <Modal
                    buttonText='OK'
                    disabled={ false }
                    action={ () => setOpenSuccessModal(false) }
                    closeAction={ () => setOpenSuccessModal(false) }
                >
                    <div className={ styles.info }>
                    Fique por dentro do calendário do Ilê, <a href='#'>clique aqui</a> e entre no grupo do WhatsApp.<br /><br />
                    Quer agendar um jogo de buzios com a Oraculo mãe Tati? <a href='#'>Clique aqui</a> e agende seu hor&aacute;rio!
                    </div>
                    <div className={ styles['confirm-text'] }>
                        Sua consulta foi agendada com sucesso!
                    </div>
                </Modal>
            }
        </div>
    );
}

export default Scheduling;