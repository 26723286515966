import React, { useState } from 'react';

import { IItem } from '../interfaces';
import styles from '../scss/components/select-multi-item.module.scss';

const SelectMultiItem = (props: { item: IItem }) => {
    const [ toggleItem, setToggleItem ] = useState<boolean>(false);
    const item = props.item;

    return (
        <li
            key={ item.id }
            className={`${ styles.item } ${ toggleItem && styles.checked }`}
            value={ item.id }
            onClick={ () => { setToggleItem(value => !value) } }
        >
            <span className={ styles.checkbox }>
                <div className={ styles.checkmark }></div>
            </span>
            <span className={ styles.text }>{ item.name }</span>
        </li>
    );
}

export default SelectMultiItem;